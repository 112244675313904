@font-face {
  font-family: 'areaplan';
  src:  url('../assets/fonts/areaplan/areaplan.eot?m4j33t');
  src:  url('../assets/fonts/areaplan/areaplan.eot?m4j33t#iefix') format('embedded-opentype'),
    url('../assets/fonts/areaplan/areaplan.ttf?m4j33t') format('truetype'),
    url('../assets/fonts/areaplan/areaplan.woff?m4j33t') format('woff'),
    url('../assets/fonts/areaplan/areaplan.svg?m4j33t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "areaplan";
    src: url("../assets/fonts/areaplan/areaplan.svg#areaplan") format("svg");
  }
}

.ap:before{
  display: inline-block;
  font-family: "areaplan";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.ap-add:before {
  content: "\e90b";
}
.ap-info:before {
  content: "\e90c";
}
.ap-cancel:before {
  content: "\e900";
}
.ap-calculate:before {
  content: "\e901";
}
.ap-cursor:before {
  content: "\e902";
}
.ap-drag:before {
  content: "\e903";
}
.ap-pencil:before {
  content: "\e904";
}
.ap-printer:before {
  content: "\e905";
}
.ap-ruler:before {
  content: "\e906";
}
.ap-save:before {
  content: "\e907";
}
.ap-width:before {
  content: "\e908";
}
.ap-zoom-out:before {
  content: "\e909";
}
.ap-zoom-in:before {
  content: "\e90a";
}

/* latin */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/almarai/Almarai-Light.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/almarai/Almarai-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/almarai/Almarai-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../assets/fonts/almarai/Almarai-ExtraBold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}