body {
  font-family: 'Almarai', sans-serif;
  font-size: 15px;
}

.header {
  background-image: url('/images/header_bg.jpg');
  background-size: 100%;
}

.text-dark-gray {
  color: $dark-gray
}
.bg-dark-gray {
  background: $dark-gray
}
.text-light-gray {
  color: $light-gray
}
.bg-light-gray {
  background: $light-gray
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.add-button {
  &.squared {
    padding-top: 100%;
    .add-button-content {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.floating-toolbar {
  position: absolute;
  left: 0;
  top: 0;
  &:not(.no-border) {
    border: 2px solid lightgray!important;
    border-radius: 5px;
    background-color: #cbe4aa;
  }
  padding: 5px;
  .drag-indicator {
    > i {
      font-size: 30px!important;
    }
    cursor: pointer;
    margin: -10px -10px 0px -10px;
  }
  > .toolbar-button, .sub-toolbar, .toolbar-button-group {
    .toolbar-button {
      width: 43px;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
      @media (max-width: 1280px) {
        margin-bottom: 3px;
      }
    }
    &.active {
      border-color: $accent!important;
      background-color: $accent;
      * {
        color: white!important;
      }
    }
    &:not(.toolbar-button-group):not(.active):hover, > .toolbar-button:not(.active):hover {
      background: darken(#fff, 5%);
    }
    cursor: pointer;
    //height: 53px;
    font-size: 22px;
    &.toolbar-button-sm {
      width: 33px;
      height: 33px;
      font-size: 18px;
    }
    text-align: center;
    align-items: center;
    background: #fbfbfb;
    border: 2px solid lightgray!important;
    border-radius: 5px;
    .label-button {
      font-size: 15px;
      font-weight: normal;
      margin: 5px;
    }
  }
  .toolbar-button {
    height: 47px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > i {
      display: flex;
      align-items: center;
    }
    :not(i) {
      margin-top: 2px;
      font-size: 8px; 
      color: grey; 
      font-weight: 700;
    }
    &[disabled] {
      pointer-events: none;
      cursor: not-allowed!important;
      background: darken(white, 7%);
      color: lighten($primary, 50%) !important;
      opacity: 1!important;
      border-color: darken(#fff, 7%);
    }
    &:not(.text-button:not(.toolbar-button-sm)) {
      width: 47px;
    }
    &.text-button {
      width: 100%;
      height: 40px;
      padding: 4px 10px!important;
      display: flex;
      text-align: left;
      &.active {
        background-color: $accent;
        .label-button {
          color: white!important;
        }
      }
      .label-button {
        width: 100%;
        white-space: nowrap;
        color: gray!important;
      }
    }
    .sub-toolbar {
      background: #fff!important;
      display: none;

      &.width-auto {
        width: max-content;
      }

      .percentage-options {
        &:nth-child(odd) {
          grid-column: 1;
        }
        &:nth-child(even) {
          grid-column: 2;
        }
        // padding: 3px 6px;
        // &:first-child {
        //   padding-top: 6px;
        // }
        // &:last-child {
        //   padding-bottom: 6px;
        // }
        background: #fff!important;
        > div {
          width: 35px;
          height: 35px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          // &:hover, &.active {
          //   border: 2px solid rgba(0,0,0,0.2);
          // }
        }
      }
      .toolbar-button:first-child:before {
        content:'';
        position: absolute;
        width: 12px;
        height: 200%;
        top: 0;
        left: -12px;
      }
      // Triangolino
      .toolbar-button:first-child:after {
        content:'';
        display:block;
        position:absolute;

        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 12px solid white;
        z-index: 1;
        left: -12px;

        top: calc(50% - 8px);
      }
      position: absolute!important;
      top: -2px;
      left: calc(100% + 12px);
    }
  }
  &.position-right .sub-toolbar {
    right: calc(100% + 12px) !important;
    left: unset;
    .toolbar-button:first-child:before {
      right: -16px;
      left: unset;
    }
    .toolbar-button:first-child:after {
      border-left: 12px solid white;
      border-right: none;
      z-index: 1;
      right: -12px;
      left: unset;

      top: calc(50% - 8px);
    }
  }
  .toolbar-button:hover > .sub-toolbar {
    display: grid;
  }
  .toolbar-button-group, .sub-toolbar {
    .toolbar-button-caption {
      font-size: 8px; 
      color: grey; 
      font-weight: 700;
    }
    .toolbar-button:first-child {
     border-top-left-radius: 4px;
     border-top-right-radius: 4px;
    }
    .toolbar-button:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

/* common */
.ribbon {
  width: 35px;
  height: 35px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 1px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 70px;
  background-color: #3498db;
  padding-top: 4px;
  padding-bottom: 15px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 9px;
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -2px;
  right: -2px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -8px;
  bottom: -6px;
  transform: rotate(-45deg);
}

.tooltip {
  &.caret-down:before {
    content:'';
    display:block;
    position:absolute;
    left: calc(50% - 8px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid white;
    z-index: 1;
    bottom: -12px;
  }
}